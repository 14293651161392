import React, { useCallback, useMemo } from "react";
import ChatStore from "../_stores/chat/ChatStore";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import { IMember } from "../_stores/chat/interfaces";
import { inject, observer } from "mobx-react";
import TailwindIcon from "library/components/_tailwind/icon";
import TailwindButton from "library/components/_tailwind/button";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindText from "library/components/_tailwind/text";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import ChatItemUsernameTooltip from "../broadcast-chat/chat-item-username-tooltip";
import TailwindKebabMenu from "library/components/_tailwind/kebab-menu";
import NotesDrawer from "./notes-drawer";
import { IShowType } from "../_stores/broadcast/enums";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import PricingStore from "../_stores/pricing/PricingStore";
import LanguageStore from "library/core/stores/language/LanguageStore";
import MedalIcon from "library/components/_tailwind/icon/icons/medal-icon";
import { CHAT_MEMBER_COLOR_MAPPING } from "../_stores/chat/consts";

interface Props {
  member: IMember;
  nodeChatStore?: NodeChatStore;
  chatStore?: ChatStore;
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  index: number;
  isVoyeur?: boolean;
  languageStore?: LanguageStore;
}

const BroadcastMemberItem: React.FunctionComponent<Props> = ({
  member,
  nodeChatStore,
  chatStore,
  broadcastStore,
  pricingStore,
  isVoyeur,
  languageStore,
}) => {
  const {
    isNotesDrawerOpen,
    MemberNotes,
    setisEditingNotes,
    isEditingNotes,
    deleteNote,
    editNote,
    submitNewNote,
  } = chatStore!;
  const {
    muteMember,
    kickMember,
    unmuteMember,
    startWhisperTo,
    activeMemberToWhisper,
    membersWhoWhispered,
  } = nodeChatStore!;
  const {
    inviteToPrivate,
    isInPaidShow,
    isInPrivateOrAdminShow,
    currentShowType,
    bountyOrderMember,
    fanClubOrderMember,
  } = broadcastStore!;

  const { modelProducts } = pricingStore!;
  const { intl } = languageStore!;

  const handleClickWhisper = useCallback(() => {
    startWhisperTo(member);
  }, [member]);

  const handleClickNotes = useCallback(() => {
    if (member.pwsid) {
      (window as any).top.open(
        `https://models.streamray.com/p/cams/user.cgi?mid=${member.pwsid}`,
        "_blank"
      );
    }
  }, [member.pwsid]);

  const handleClickInviteToPrivate = useCallback(() => {
    inviteToPrivate(member);
  }, [member]);

  const handleClickMemberMute = useCallback(() => {
    if (!member.isInvisible) {
      muteMember(member.id);
    } else {
      unmuteMember(member.id);
    }
  }, [member]);

  const handleClickMemberKick = useCallback(() => {
    kickMember(member.id);
  }, []);

  const canInviteToPrivate = useMemo(() => {
    return !isInPaidShow && currentShowType !== IShowType.TIPPING;
  }, [isInPaidShow, currentShowType]);

  const canWhisper = useMemo(() => {
    return !isInPrivateOrAdminShow && !isVoyeur;
  }, [isInPrivateOrAdminShow, isVoyeur]);

  const username = useMemo(() => {
    if (!member.id || !member.username) return "";
    if (member?.id.includes("ffadult")) {
      return member?.username + "+ffadult";
    } else if (member?.id.includes("bdsm")) {
      return member?.username + "+bdsm";
    } else {
      return member.username;
    }
  }, [member]);

  const activeWhipser = useMemo(() => {
    return membersWhoWhispered.some(username => username === username);
  }, [membersWhoWhispered, username]);

  const isWhisperInSeparateTabsActive = useMemo(
    () =>
      modelProducts?.chat_settings?.system_settings?.whispers_in_separate_tabs,
    [modelProducts]
  );

  const bountyOrdered = bountyOrderMember === member.username;
  const fanClubOrdered = fanClubOrderMember === member.username;

  return (
    <>
      <TailwindFlex
        borderRadius={"rounded-lg"}
        padding={["p-0.5", isVoyeur ? "pr-4" : "pr-2", "pl-4"]}
        flexDirection={"flex-col"}
        margin={["mt-1"]}
        key={member.id}
        position='relative'
        width='w-full'
        backgroundColor='bg-card-secondary-bg-color'
        className={[
          `BroadcastViewers__member-item ${activeWhipser ? "whispered" : ""} ${
            !isWhisperInSeparateTabsActive &&
            username === activeMemberToWhisper?.username
              ? "whisperedActive"
              : ""
          }`,
        ]}>
        <TailwindFlex
          alignItems={"items-center"}
          justifyContent={"justify-between"}
          width={"w-full"}>
          <TailwindFlex
            flexDirection='flex-col'
            width='w-40'
            alignItems={"items-start"}>
            <TailwindText
              textColor={"text-main-color"}
              padding={["pr-1"]}
              width='w-full'
              borderRadius={"rounded"}
              textOverflow='truncate'
              backgroundColor={"bg-transparent"}
              fontWeight={"font-bold"}>
              {member && (
                <ChatItemUsernameTooltip
                  userId={member?.id}
                  userName={username}>
                  {username}
                </ChatItemUsernameTooltip>
              )}
            </TailwindText>

            <TailwindFlex
              alignItems={"items-center"}
              width='w-40'
              margin={["mr-2"]}>
              {member?.topAdmirer && (
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.top-admirer-no-rank",
                    defaultMessage: "Top Admirer",
                  })}>
                  <span>
                    <MedalIcon
                      style={{
                        color: CHAT_MEMBER_COLOR_MAPPING.TOP_ADMIRER,
                        marginRight: "0.5rem",
                      }}
                      iconRange={member?.topAdmirer as any}
                    />
                  </span>
                </TailwindTooltip>
              )}

              {(member?.isBounty || (bountyOrdered && !member?.isBounty)) && (
                <TailwindTooltip
                  place='bottom'
                  content={intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.bounty",
                    defaultMessage: "Bounty",
                  })}>
                  <span className='ChatItem__BountyIcon'>
                    <TailwindIcon
                      margin={["mr-2"]}
                      name='bounty-icon'></TailwindIcon>
                  </span>
                </TailwindTooltip>
              )}

              {(member?.isFanClub || (fanClubOrdered && !member?.isFanClub)) && (
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.fan-club-member",
                    defaultMessage: "Fun Club Member",
                  })}>
                  <span>
                    <TailwindIcon
                      margin={["mr-2"]}
                      style={{
                        color: CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_ICON,
                      }}
                      name='star'></TailwindIcon>
                  </span>
                </TailwindTooltip>
              )}

              {member?.isHighValue && (
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.high-value-member",
                    defaultMessage: "High Value Member",
                  })}>
                  <span>
                    <TailwindIcon
                      margin={["mr-2"]}
                      style={{
                        color: CHAT_MEMBER_COLOR_MAPPING.FAN_COLOR_ICON,
                      }}
                      name='high-value-icon'></TailwindIcon>
                  </span>
                </TailwindTooltip>
              )}

              {member?.isBountyTarget && !bountyOrdered ? (
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast.chatItem.tooltip-member-labels.bounty-target",
                    defaultMessage: "Bounty Target",
                  })}>
                  <span className='ChatItem__BountyIcon'>
                    <img
                      src='\images\icons\exclamation_animation.png'
                      alt='bounty target icon'
                      width='20'
                      height='20'
                    />
                  </span>
                </TailwindTooltip>
              ) : (
                ""
              )}

            </TailwindFlex>
          </TailwindFlex>

          <TailwindFlex
            width={"w-1/2"}
            alignItems={"items-center"}
            justifyContent={"justify-around"}>
            {member.isInvisible && (
              <TailwindFlex
                flexDirection='flex-row'
                borderRadius={"rounded-full"}
                backgroundColor={"bg-gray-400"}
                padding={["px-2", "py-2"]}
                textColor={"text-white"}>
                <TailwindText fontSize={"text-sm"}>{"Muted"}</TailwindText>
                <TailwindFlex
                  margin={["ml-1"]}
                  justifyContent='justify-center'
                  alignItems='items-center'
                  width='w-5'
                  height='h-5'
                  backgroundColor={"bg-gray-600"}
                  borderRadius={"rounded-full"}
                  onClick={handleClickMemberMute}>
                  <TailwindIcon name={"close"} fontSize={"text-sm"} />
                </TailwindFlex>
              </TailwindFlex>
            )}

            {!member.isInvisible && (
              <>
                {canInviteToPrivate && (
                  <TailwindTooltip
                    content={intl.formatMessage({
                      id: "member-item.invite-to-private",
                      defaultMessage: "Invite To Private",
                    })}>
                    <TailwindFlex
                      padding={["pl-2"]}
                      flexDirection='flex-col'
                      justifyContent='justify-center'
                      alignItems='items-center'>
                      <TailwindButton
                        fullWidth={false}
                        backgroundColor={"bg-transparent"}
                        rounded={false}
                        onClick={handleClickInviteToPrivate}
                        borderRadius={"rounded-lg"}
                        textProps={{ whiteSpace: "whitespace-nowrap" }}
                        size={"xs"}
                        lineHeight={"leading-snug"}>
                        <TailwindIcon
                          name={"invite-to-private"}
                          textColor={"text-main-color"}
                        />
                      </TailwindButton>
                      <TailwindText
                        textColor='text-main-color'
                        fontSize='text-xs'>
                        {intl.formatMessage({
                          id: "member-item.private",
                          defaultMessage: "Private",
                        })}
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindTooltip>
                )}
                {canWhisper && (
                  <TailwindTooltip
                    content={intl.formatMessage({
                      id: "member-item.send-whisper",
                      defaultMessage: "Send Whisper",
                    })}>
                    <TailwindFlex
                      flexDirection='flex-col'
                      padding={["pl-2"]}
                      justifyContent='justify-center'
                      alignItems='items-center'>
                      <TailwindButton
                        fullWidth={false}
                        backgroundColor={"bg-transparent"}
                        rounded={false}
                        size={"xs"}
                        onClick={handleClickWhisper}
                        borderRadius={"rounded-lg"}
                        lineHeight={"leading-snug"}>
                        <TailwindIcon
                          name={"private-message"}
                          textColor={"text-main-color"}
                        />
                      </TailwindButton>
                      <TailwindText
                        textColor='text-main-color'
                        fontSize='text-xs'>
                        {intl.formatMessage({
                          id: "member-item.whisper",
                          defaultMessage: "Whisper",
                        })}
                      </TailwindText>
                    </TailwindFlex>
                  </TailwindTooltip>
                )}
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "member-item.notes",
                    defaultMessage: "Notes",
                  })}>
                  <TailwindFlex
                    flexDirection='flex-col'
                    padding={["pl-2"]}
                    justifyContent='justify-center'
                    alignItems='items-center'>
                    <TailwindButton
                      fullWidth={false}
                      backgroundColor={"bg-transparent"}
                      rounded={false}
                      size={"xs"}
                      onClick={handleClickNotes}
                      borderRadius={"rounded-lg"}
                      lineHeight={"leading-snug"}>
                      <TailwindIcon
                        name={"notes-pencil"}
                        textColor={"text-main-color"}
                      />
                    </TailwindButton>
                    <TailwindText
                      textColor='text-main-color'
                      fontSize='text-xs'>
                      {intl.formatMessage({
                        id: "member-item.notes",
                        defaultMessage: "Notes",
                      })}
                    </TailwindText>
                  </TailwindFlex>
                </TailwindTooltip>
              </>
            )}

            {!isVoyeur && (
              <TailwindKebabMenu otherOptions={true} fontSize='text-9xl'>
                <TailwindFlex
                  justifyContent='justify-between'
                  flexDirection='flex-row'
                  onClick={handleClickMemberMute}
                  alignItems={"items-center"}>
                  <TailwindText textColor='text-main-color' fontSize='text-sm'>
                    {!member.isInvisible
                      ? intl.formatMessage({
                          id: "member-item.mute",
                          defaultMessage: "Mute",
                        })
                      : intl.formatMessage({
                          id: "member-item.unmute",
                          defaultMessage: "Unmute",
                        })}
                  </TailwindText>
                  <TailwindButton
                    fullWidth={false}
                    backgroundColor={"bg-transparent"}
                    rounded={false}
                    display='hidden'
                    size={"sm"}
                    borderRadius={"rounded-lg"}
                    lineHeight={"leading-snug"}>
                    <TailwindIcon
                      name={!member.isInvisible ? "mute" : "unmute"}
                      textColor={"text-main-color"}
                    />
                  </TailwindButton>
                </TailwindFlex>
                <TailwindFlex
                  justifyContent='justify-between'
                  flexDirection='flex-row'
                  onClick={
                    currentShowType === IShowType.PRIVATE
                      ? () => {}
                      : handleClickMemberKick
                  }
                  alignItems={"items-center"}
                  opacity={
                    currentShowType === IShowType.PRIVATE
                      ? "opacity-50"
                      : "opacity-100"
                  }
                  cursor={
                    currentShowType === IShowType.PRIVATE
                      ? "cursor-not-allowed"
                      : "cursor-pointer"
                  }>
                  <TailwindText textColor='text-main-color' fontSize='text-sm'>
                    {intl.formatMessage({
                      id: "member-item.kick",
                      defaultMessage: "Kick",
                    })}
                  </TailwindText>
                  <TailwindButton
                    fullWidth={false}
                    backgroundColor={"bg-transparent"}
                    rounded={false}
                    disabled={
                      currentShowType === IShowType.PRIVATE ||
                      currentShowType === IShowType.GROUP
                    }
                    size={"sm"}
                    borderRadius={"rounded-lg"}
                    lineHeight={"leading-snug"}>
                    <TailwindIcon name={"kick"} textColor={"text-main-color"} />
                  </TailwindButton>
                </TailwindFlex>
              </TailwindKebabMenu>
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
      {isNotesDrawerOpen === member.id && (
        <NotesDrawer
          notes={MemberNotes}
          onAdd={submitNewNote}
          memberId={member.id}
          onDelete={deleteNote}
          onEdit={editNote}
          isEditingNotes={isEditingNotes}
          setisEditingNotes={setisEditingNotes}
          activeDrawer={isNotesDrawerOpen}
          onCloseDrawer={handleClickNotes}
        />
      )}
    </>
  );
};

export default inject(
  "nodeChatStore",
  "chatStore",
  "broadcastStore",
  "pricingStore",
  "languageStore"
)(observer(BroadcastMemberItem));
